<template>
  <v-card>
    <v-card-title>
      <h4 class="dxa_modal_title h4">{{ $t("manage_highlights") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="add_highlight()" ref="form" class="mt-5">
        <v-select
          :items="index_options"
          :label="$t('index')"
          v-model="highlight.Index"
          outlined
        ></v-select>
        <v-row>
          <v-col cols="1">
            <v-select
              :items="AvailableLangs"
              :label="$t('language')"
              v-model="selectedLang"
              :rules="[required]"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="11">
            <v-text-field
              :label="$t('text')"
              v-model="highlight.Text[selectedLang]"
              :rules="[required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close')"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "ManageHighlightDialog",

  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    selectedLang: null,
    highlight: {
      Text: {},
      Index: null,
    },
    index_options: [],
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    Highlight: Object,
    HighlightCount: Number,
    AvailableLangs: Array,
  },

  async created() {
    this.highlight = JSON.parse(JSON.stringify(this.Highlight));
    this.index_options = Array.from(
      {
        length: this.Highlight.Id
          ? this.HighlightCount
          : this.HighlightCount + 1,
      },
      (_, i) => i + 1
    );
    this.selectedLang = this.$i18n.locale;
  },

  methods: {
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.add_highlight();
      } else {
        this.loading = false;
      }
    },
    add_highlight: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;

      var obj = JSON.parse(JSON.stringify(this.highlight));
      obj.Text = JSON.stringify(this.highlight.Text);

      if (this.highlight.Id) {
        await this.apiService
          .putRequest("companyhighlight/edit", obj)
          .then((result) => {
            this.$emit("save");
          })
          .catch((error) => {
            this.$toast.error(this.$t(error.body.message));
          });
      } else {
        await this.apiService
          .postRequest("companyhighlight/add", obj)
          .then((result) => {
            this.$emit("save");
          })
          .catch((error) => {
            this.$toast.error(this.$t(error.body.message));
          });
      }
      this.loading = false;
    },
  },
};
</script>
